import isAfter from 'date-fns/isAfter';
import { ExternalProvider, Maybe, Order, Trip } from 'api/generated';
import { StateCodesEnum } from 'lib/constants';

export type ListTrip = {
  createdAt?: Maybe<string>;
  orders: Order[];
  heldBackFromPicking: boolean;
  states: number[];
};

const compareCreatedAt = (a: any, b: any) => {
  const d1 =
    a?.activeTimestamp || a?.plannedOrderInformation?.activeTimestamp || a?.createdAt || '';
  const d2 =
    b?.activeTimestamp || b?.plannedOrderInformation?.activeTimestamp || b?.createdAt || '';

  if (d2 && d1) {
    return +new Date(d2).valueOf() - new Date(d1).valueOf();
  }

  return 0;
};

export const searchTrips = (trips: ListTrip[], searchedOrders?: Order[]): ListTrip[] => {
  if (!searchedOrders?.length || !trips.length) return [];

  const hubOrders = searchedOrders.reduce(
    (acc, o) => (o.id ? { ...acc, [o.id]: o } : acc),
    {} as { [orderId: string]: Order }
  );

  const filteredTrips = trips.filter((trip) => {
    const firstOrder = hubOrders[trip.orders[0]?.id as string];
    const secondOrder = hubOrders[trip.orders[1]?.id as string];
    delete hubOrders[trip.orders[0]?.id as string];
    delete hubOrders[trip.orders[1]?.id as string];

    return !!(firstOrder || secondOrder);
  });

  // When orders received from search endpoint doesn't exist on trips built on getTrips function
  Object.values(hubOrders).forEach((order) => {
    filteredTrips.push({
      createdAt: order.createdAt,
      heldBackFromPicking: false,
      orders: [order],
      states: [order.state as number],
    });
  });

  return filteredTrips.sort(compareCreatedAt);
};

/*
  This function combines the result of the orders and dispatching endpoints.
  We go from a list of { orderIds: string[] }[] to { order: Order[] }[]
*/

export const getTrips = (trips: Trip[], orders: Order[]): ListTrip[] => {
  const hubOrders = orders.reduce(
    (acc, o) => (o.id ? { ...acc, [o.id]: o } : acc),
    {} as { [orderId: string]: Order }
  );

  const orderTrips: any[] = trips.flatMap(({ orders, heldBackFromPicking }) => {
    const states: number[] = [];
    const tripOrders = (orders || [])
      ?.flatMap((o: any) => {
        const id = o?.orderId || '';
        let order = hubOrders[id];

        if (o?.plannedOrderInformation) {
          order = { ...order, plannedOrderInformation: o?.plannedOrderInformation };
        }

        delete hubOrders[id];
        if (order) {
          states.push(order.state as number);
          return [order];
        } else {
          return [];
        }
      })
      .sort(compareCreatedAt);

    return tripOrders.length > 0
      ? {
          createdAt: tripOrders[0].createdAt,
          activeTimestamp: tripOrders[0]?.plannedOrderInformation?.activeTimestamp,
          heldBackFromPicking: heldBackFromPicking,
          orders: tripOrders,
          states,
        }
      : [];
  });

  // Fallback logic if the trips endpoint is missing some orders
  // TODO: This might not be needed if dispatching and hub-orders services are in sync
  // Do not remove, it is important - workaround for external orders like Wolt etc.
  Object.values(hubOrders).forEach((order) => {
    orderTrips.push({
      createdAt: order.createdAt,
      heldBackFromPicking: false,
      orders: [order],
      states: [order.state as number],
    });
  });

  return orderTrips.sort(compareCreatedAt) as ListTrip[];
};

export type CustomStats = {
  queuedOrderCount: number;
  readyToPickCount: number;
  pickingCount: number;
  readyToDeliverCount: number;
  externalReadyToDeliverCount: number;
};

const isInQueue = (trip: ListTrip) => {
  const isInactive = trip?.orders?.reduce((acc, o) => {
    if (o?.plannedOrderInformation) {
      if (
        isAfter(Date.parse(o?.plannedOrderInformation?.activeTimestamp || ''), new Date().getTime())
      ) {
        return true;
      }
    }
    return acc;
  }, false);

  return isInactive ? false : trip.heldBackFromPicking;
};

const inQueueOrdersCount = (trip: ListTrip) => {
  return trip?.orders?.filter((o) => o?.state === StateCodesEnum.CREATED).length || 1;
};

export const getCalculatedStats = (trips: ListTrip[]) => {
  const initialState = {
    queuedOrderCount: 0,
    readyToPickCount: 0,
    pickingCount: 0,
    readyToDeliverCount: 0,
    externalReadyToDeliverCount: 0,
  };
  if (!trips.length) return initialState;

  return trips.reduce((acc, trip): CustomStats => {
    const queuedOrderCount = isInQueue(trip)
      ? acc.queuedOrderCount + inQueueOrdersCount(trip)
      : acc.queuedOrderCount;

    const notPackedOrdersLength =
      !trip.heldBackFromPicking &&
      trip.orders.filter((order) => order.state === StateCodesEnum.CREATED).length;

    const pickingOrdersLength = trip.orders.filter(
      (order) => order.state === StateCodesEnum.PICKERACCEPTED
    ).length;

    const pickingCount = pickingOrdersLength
      ? acc.pickingCount + pickingOrdersLength
      : acc.pickingCount;

    const readyToPickCount = notPackedOrdersLength
      ? acc.readyToPickCount + notPackedOrdersLength
      : acc.readyToPickCount;

    const isTripReady = trip.orders.every((order) => order.state === StateCodesEnum.PACKED);

    const readyToDeliverCount = isTripReady ? ++acc.readyToDeliverCount : acc.readyToDeliverCount;

    const externalReadyToDeliverCount =
      isTripReady &&
      trip.orders.some(
        (order) =>
          [
            ExternalProvider.UberEats,
            ExternalProvider.UberEatsCarrefour,
            ExternalProvider.Wolt,
            ExternalProvider.JustEat,
            ExternalProvider.JustEatRewe,
          ].includes(order.externalProvider as ExternalProvider) || order.isOutsourced
      )
        ? acc.externalReadyToDeliverCount + 1
        : acc.externalReadyToDeliverCount;

    return {
      queuedOrderCount,
      readyToPickCount,
      pickingCount,
      readyToDeliverCount,
      externalReadyToDeliverCount,
    };
  }, initialState);
};
