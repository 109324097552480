import { Order } from '@hub-orders-bff/schema/types';
import { LocaleT } from '@hubportal/sdk';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import { enGB, fr, de, nl } from 'date-fns/locale';

export const dateLocale = (loc: LocaleT) => {
  const localeMap = new Map<LocaleT, Locale>([
    ['en', enGB],
    ['fr', fr],
    ['de', de],
    ['nl', nl],
  ]);

  return localeMap.get(loc) || enGB;
};

export const isPlannedOrder = (order: Order): boolean => {
  return order?.isPlanned ? true : false;
};

export const formatPDT = (order: Order, locale: LocaleT) => {
  if (isPlannedOrder(order)) {
    return `${format(new Date(order.plannedOrderInformation?.deliveryWindowStart || ''), 'HH:mm', {
      locale: dateLocale(locale),
    })} - ${format(new Date(order.plannedOrderInformation?.deliveryWindowEnd || ''), 'HH:mm', {
      locale: dateLocale(locale),
    })}`;
  }

  return order?.promisedDeliveryDuration
    ? `${Math.round(order?.promisedDeliveryDuration / 60)} min`
    : '';
};

export const isDeliveredLate = (order: Order) => {
  if (isPlannedOrder(order)) {
    if (
      isAfter(
        Date.parse(order?.deliveredAt || ''),
        Date.parse(order?.plannedOrderInformation?.deliveryWindowEnd || '')
      )
    ) {
      return true;
    }
    return false;
  }

  return order.deliveryDuration && order.promisedDeliveryDuration
    ? order.deliveryDuration >
        order.promisedDeliveryDuration + (order.promisedDeliveryDuration * 15) / 100
    : false;
};

export const randomIntFromInterval = (min: number, max: number) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};
